<template>
  <main :class="[`${form.formType}`, { bannerLow: isAbTestBanner || isQuickWinForm }]">
    <div class="banner-wrapper">
      <StepperBanner v-if="!isDonation && !isSale" />
    </div>
    <div class="CampaignWrapper Container">
      <FormPublishState v-if="isUnPublished" :state="form.state" />
      <StepperView
        v-if="!isDonation && !isSale"
        :step="step"
        :navigate="navigate"
        :current-step-component="getCurrentStepComponent()"
      />
      <ActionReport v-if="showActionReport" />
      <DonationView v-if="isDonation" />
      <SaleView v-if="isSale" />
    </div>
  </main>
</template>

<script>
import { UrlToApiTypes } from '@/helpers/enums'
import useFormType from '@/composables/useFormType'
import useStoreData from '@/composables/useStoreData'
import { FormSteps } from '@/helpers/formConfig'
import StepperBanner from '@/components/forms/stepper/partials/StepperBanner.vue'
import FormPublishState from '@/components/forms/informations/FormPublishState.vue'
import StepperView from '@/components/forms/stepper/StepperView.vue'
import DonationView from '@/components/forms/donations/DonationView.vue'
import SaleView from '@/components/forms/sales/SaleView.vue'
import useTracking from '@/composables/useTracking'
import { useHeaderPublic } from '@ha/components'
import nodeEnv from '@/helpers/nodeEnv.ts'
import { isUserConnected } from '@/helpers/auth'
import { getMicroData } from '@/helpers/seo'
import useModalities from '~/composables/useModalities'
import useAbTasty from '~/composables/useAbTasty'
import ActionReport from '@/components/reports/ActionReport.vue'

const debug = require('debug')('ha-forms:_steps')

export default {
  name: 'Steps',
  components: {
    StepperView,
    StepperBanner,
    FormPublishState,
    DonationView,
    SaleView,
    ActionReport
  },
  meta: { requiresAuth: false },
  props: {
    errorStatus: {
      type: [String, Number],
      required: false
    }
  },
  setup() {
    const { form, organization, user } = useStoreData()
    const { isSale, isDonation, isShop, isCrowdfunding, isQuickWinForm } = useFormType()
    const { trackingIdentify } = useTracking()
    const { setUser, setOrganizations, isLoadingUser } = useHeaderPublic()
    const { isPaymentTermsEnabled } = useModalities()
    const { initAbTasty, isVariationD, isVariationE } = useAbTasty()

    const trackUser = () => {
      if (!user.value?.userId) return

      trackingIdentify(user.value.userId, {
        userId: user.value.userId
      })
    }

    trackUser()

    if (isPaymentTermsEnabled) {
      if (form.value.availableFrequencies?.length) {
        const availableTiersFrequencies = form.value.tiers.map(tier => {
          return {
            [tier.label]: tier.isExcludedFromFormPaymentTerms
              ? 'Indisponible au paiement en plusieurs fois'
              : 'Payable en plusieurs fois'
          }
        })

        if (!nodeEnv.isProduction) {
          console.info('Cette campagne est éligible aux modalités de paiement.', {
            'Fréquences de paiement disponibles': form.value.availableFrequencies,
            'Liste des tiers': availableTiersFrequencies
          })
        }
      } else {
        if (!nodeEnv.isProduction) {
          console.info("Cette campagne n'est pas éligible aux modalités de paiement.")
        }
      }
    }

    return {
      form,
      organization,
      user,
      isSale,
      isDonation,
      isShop,
      isCrowdfunding,
      setUser,
      setOrganizations,
      isLoadingUser,
      initAbTasty,
      isVariationD,
      isVariationE,
      isQuickWinForm
    }
  },
  beforeMount() {
    this.initAbTasty('abcdTestFirstStepCV')
  },
  asyncData({ params }) {
    // set data with route params (before rendering the comp)
    return {
      organizationSlug: params.organizationSlug?.replace(/\s/g, '-'),
      campaignSlug: params.campaignSlug?.replace(/\s/g, '-'),
      type: params.formType
    }
  },
  async fetch({ store, params, error, app }) {
    const orgSlug = params.organizationSlug?.replace(/\s/g, '-')
    // build "metadata" with get params of the route
    const metadata = {
      organization: orgSlug,
      slug: params.campaignSlug?.replace(/\s/g, '-'),
      type: UrlToApiTypes[params.formType]
    }
    try {
      await store.dispatch('forms/fetchFormIfNeeded', metadata)

      if (isUserConnected(app.$cookies)) {
        await store.dispatch('user/fetchMeIfNeeded')
      }
    } catch (err) {
      if (!nodeEnv.isProduction) debug(err)
      return error(err)
    }
  },
  computed: {
    formSteps() {
      if ((this.isShop || this.isCrowdfunding) && !this.hasCustomFieldsOrOptions) {
        return FormSteps[this.metadata.type].filter((item, index) => index !== 1)
      }
      return FormSteps[this.metadata.type]
    },
    hasCustomFieldsOrOptions() {
      return !!this.form?.tiers?.find(
        item => item.customFields?.length || item.extraOptions?.length
      )
    },
    step() {
      return parseInt(this.$route.params.steps, 10) || 1
    },
    baseCampaignUrl() {
      return `/associations/${this.organizationSlug}/${this.type}/${this.campaignSlug}`
    },
    isUnPublished() {
      return ['Draft', 'Disabled', 'Deleted'].includes(this.form?.state)
    },
    isAbTestBanner() {
      return this.isVariationD || this.isVariationE
    },
    isFirstStep() {
      return this.step === 1
    },
    showActionReport() {
      return !this.isDonation && !this.isSale && this.isFirstStep && this.isQuickWinForm
    }
  },
  jsonld() {
    return getMicroData(this.form, this.organization)
  },
  created() {
    // Available only for 'formulaires' -> donation
    if (this.$route.name === 'EnglishRoute') {
      this.$i18n.setLocale('en')
    } else {
      this.$i18n.setLocale('fr')
    }
  },
  mounted() {
    this.scrollIntoTargetElement()
    // Script is declared in gtm
    this.$gtm.push('realytics-default')

    // Need to set users in header - doesn't work server side (fetch)
    const hasUserOrganization = this.$store.getters['user/getUserOrganizations']().length
    const cookies = this.$nuxt.context.app.$cookies

    if (isUserConnected(cookies)) {
      this.isLoadingUser = true
      this.setUser(this.$store.getters['user/getMe']())
      if (hasUserOrganization) {
        this.setOrganizations(this.$store.getters['user/getUserOrganizations']())
      }
      this.isLoadingUser = false
    }
  },
  methods: {
    /**
     * Wraps the $router `push` method to add our URL metadata
     * @param {number} nextStep
     */
    navigate(nextStep) {
      this.$router.push({
        path: `${this.baseCampaignUrl}/${nextStep}`
      })
    },
    getCurrentStepComponent() {
      if (this.step <= this.formSteps?.length) {
        return this.formSteps[this.step - 1]?.component
      }
      return this.formSteps[0]?.component
    },
    scrollIntoTargetElement() {
      const { hash, params } = this.$route
      const anchorElementName = '#anchorToTickets'
      const targetScrollElement = this.$el.querySelector(hash || anchorElementName)
      const canScrollToCampaignStep = !!(params.steps && targetScrollElement)
      const canScrollToHash = !!(hash && targetScrollElement)
      const canScroll = canScrollToHash || canScrollToCampaignStep
      if (canScroll) {
        /* Need to use setTimeout to force scrolling */
        setTimeout(() => {
          const yOffset = hash ? 0 : -80
          const anchorPosition =
            targetScrollElement.getBoundingClientRect().top + window.pageYOffset + yOffset
          window.scroll({ top: anchorPosition })
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.PaymentForm {
  overflow-x: hidden;
}

.banner-wrapper {
  aspect-ratio: 1920 / 250;

  &:empty {
    display: none;
  }
}

.bannerLow {
  padding-top: calc(var(--header-height) + var(--header-cv-height));
}

main {
  :deep(.ActionReport--Button--quickWin) {
    margin-top: calc($ha-spacing-big*2);
  }
}

</style>
