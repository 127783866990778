<script>
import { UrlToApiTypes } from '@/helpers/enums'
import { getCustomColor } from '@/helpers/color'
import { defaultColor } from '@/constants'
import { enum as enums, functions } from '@ha/helpers'

export default {
  name: 'WidgetVignetteHorizontale',
  components: {},
  layout: 'widget',
  async fetch({ store, params, error }) {
    const orgSlug = params.organizationSlug?.replace(/\s/g, '-')

    // build "metadata" with get params of the route
    const metadata = {
      organization: orgSlug,
      slug: params.campaignSlug?.replace(/\s/g, '-'),
      type: UrlToApiTypes[params.formType]
    }

    try {
      // refaco #enrichedForms - can be direcly use forms
      await store.dispatch('forms/fetchEnrichedForm', metadata)
    } catch (err) {
      const statusCode = (err?.response?.status ?? err?.statusCode) || 500
      const message = err?.response?.statusText ?? err?.message

      return error({ statusCode, message })
    }
    return true
  },
  head() {
    const formColors = getCustomColor(this.form.color || defaultColor)
    return {
      htmlAttrs: {
        style: formColors
      }
    }
  },
  computed: {
    form () {
      // "OR" is used to fallback inaccessible enrichedForm properties on _step by original formGetter.
      return this.$store.getters['forms/getEnrichedForm'](this.metadata) || this.$store.getters['forms/getForm'](this.metadata)
    },
    formType() {
      return this.form.formType?.toLowerCase()
    },
    vignetteUrl () {
      return this.form.logo?.publicUrl || ''
    },
    title() {
      return this.form.title
    },
    description() {
      const descriptionMaxLength = 300
      const truncatedDescription = this.form.description?.substring(0, descriptionMaxLength)
      return this.form.description?.length > 300
        ? `${truncatedDescription}... ${this.$t('widget.seeMore')}`
        : this.form.description
    },

    isCrowdfunding() {
      return this.form.formType === enums.FormType.CROWDFUNDING
    },
    isEvent() {
      return this.form.formType === enums.FormType.EVENT
    },
    isMembership() {
      return this.form.formType === enums.FormType.MEMBERSHIP
    },
    isShop() {
      return this.form.formType === enums.FormType.SHOP
    },
    isSale() {
      return this.form.formType === enums.FormType.PAYMENTFORM
    },
    isDonation() {
      return this.form.formType === enums.FormType.DONATION
    },
    defaultButtonLabel() {
      return this.$t(`campaign.step.participants.type.${this.formType}.action`)
    }
  }
}
</script>
